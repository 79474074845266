.login-card {
    text-align: center;
    margin: 0 30%;
}


.login-card > .ant-card-body {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
