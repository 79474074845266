.paid {
    font-size: 1.25em;
}

.choose-project {
    width: 11em;
    margin-left: 4px;
    margin-bottom: 18px;
}

#unknown-country-flag {
    position: relative;
    display: inline-block;
    width: 1.3333333333em;
    height: 1em;
    background-position: 50%;
    background-repeat: no-repeat;
    font-size: 2.5em;
    line-height: 1em;
    vertical-align: middle;
    border: 1px solid gray;
    background-size: 1.3333333333em 1em;
    background-image: url(../../images/unknown-country-flag.svg)
}

#svg-flag {
    font-size: 2.5em;
    border: 1px solid gray;
    background-size: 1.3333333333em 1em;
}

.purchase-date-of-bought-filter {
    padding: 1em;
}

#export-button {
    float: right;
}