.search-column-main {
    padding: 8px;
}

.search-column-main .search-column-input {
    width: 13.5em;
    margin-bottom: 8px;
    display: block
}

.search-column-main .search-button {
    width: 90px;
    margin-right: 8px
}

.search-column-main .reset-button {
    width: 90px;
}
