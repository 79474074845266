.footer {
    text-align: right
}

.footer .ant-statistic {
    display: inline-block;
}

.footer .ant-statistic .ant-statistic-content {
    color: green;
    font-size: 1em;
}
