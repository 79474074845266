.ant-btn.ant-btn-danger.ant-btn-sm.ant-btn-icon-only {
    margin: 5px;
    display: inline-block
}

.add-row-button {
    margin-top: 1em;
    margin-bottom: 1em;
    float: right
}
