.filter-input {
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    min-width: 15em;
}

.filter-input-label {
    margin-top: 1em;
}

.filter-button {
    margin-top: 0.5em;
    margin-right: 0.5em;
}

.reward-paid-button {
    font-size: 1.5em;
}

.payment-confirmation-buttons {
    text-align: right;
}

.payment-confirmation-button {
    margin: 0.3em;
}

.remaining-balance-text {
    margin-bottom: 0;
}